import { useState } from "react"
import { motion } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"

// Welcome to Code in Framer
// Get Started: https://www.framer.com/docs/guides/

/**
 * These annotations control how your component sizes
 * Learn more: https://www.framer.com/docs/guides/auto-sizing
 *
 * @framerSupportedLayoutWidth any
 * @framerSupportedLayoutHeight any
 */

export default function Download(props) {
    const { title, tint, style, file } = props

    return (
        <motion.div style={{ ...style, ...containerStyle }}>
            <motion.div
                onTap={() => {
                    const link = document.createElement("a")
                    link.href = file
                    link.download = "download"
                    document.body.appendChild(link)
                    link.click()
                    link.remove()
                }}
                style={{
                    paddingTop: 10,
                    paddingRight: 18,
                    paddingBottom: 10,
                    paddingLeft: 18,
                    borderRadius: 100,
                    backgroundColor: tint,
                    color: "white",
                    fontSize: "1em",
                    fontFamily: "Poppins",
                    textTransform: "Uppercase",
                    fontWeight: "bold",
                    fontSize: "14px",
                }}
                whileHover={{ cursor: "pointer" }}
            >
                {title}
            </motion.div>
        </motion.div>
    )
}

Download.defaultProps = {
    tint: "#09F",
    title: "Download",
}

addPropertyControls(Download, {
    tint: {
        title: "Tint",
        type: ControlType.Color,
    },
    title: {
        title: "Label",
        type: ControlType.String,
    },
    file: {
        title: "File",
        type: ControlType.File,
        allowedFileTypes: [],
    },
})

const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}
